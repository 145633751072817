<template>
   <div id="classListWraper">
       <div class="row">
            <div class="col-sm">
                <h4>Beliebte Fächer</h4>
            </div>
       </div>
       <div class="row">
            <div class="col-sm-4" v-for="item in classes" :key="item.id">
                <p>
                    <router-link :to="{ name: 'class', params: {cluster: item.formated_data,class: item.formated_subject } }">
                        <b>{{item.strSubject}}</b> - {{item.item_count | numFormat()}} Arbeiten
                    </router-link>
                </p>
            </div>
            <div class="col-sm-4">
                <p>
                    <router-link to="/faecher">
                        ...Alle Fächer
                    </router-link>
                </p>
            </div>
       </div>
   </div>
</template>

<script>
    import axios from "axios";

    export default {
        name: "ClassList",
        data: function () {
            return {
                loadComplete : false,
                classes: []
            }
        },
        methods:{
            getItems: function () {
                axios.get('https://api.pausenhof.de/classes/hotlist',
                    {headers:{'authorization': '3241451345'}}).then(response => {
                    this.classes = response.data.content;
                    this.loadComplete = true;
                });
            }
        },
        created () {
            this.getItems();
        },
    };
</script>

<style>
    #classListWraper{
        padding:25px 0;
    }
</style>