<template>
    <div class="shareWraper" style="display:none">
        <div class="container">
            <div class="row">
                <div class="col-12 text-center">
                    <h3>Wissen Teilen & Geld Verdienen</h3>
                    <p>
                        Nach der Registrierung bei uns kannst du bequem deine Arbeiten hochladen.
                        Alle Arbeiten werden von unserem Team qualifiziert, nach erfolgreicher freischaltung erhälst du dann deine Belohnung direkt auf dein PayPal Konto.
                    </p>


                    <button type="button" class="btn btn-dark">Jetzt registrieren & Arbeit Hochladen</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "ShareCollect",
    };
</script>

<style>

    .shareWraper .btn-dark{
        background-color:#22316a;
        border-color:#22316a;
    }
    .shareWraper h3{
        font-family: 'Londrina Shadow';
        font-size: 3.5rem;
    }
    .shareWraper p{
        margin:25px 0;
    }
    .shareWraper{
        padding: 35px 0 55px;
        background: #fff7c9;
    }
</style>