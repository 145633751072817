import Vue from 'vue'
import App from './App.vue'
import router from "./router";
import store from "./store";
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import numeral from 'numeral';
import numFormat from 'vue-filter-number-format';
import JwPagination from 'jw-vue-pagination';
import {toInteger} from "bootstrap-vue/esm/utils/number";
import VueCookies from 'vue-cookies';


Vue.component('jw-pagination', JwPagination);

Vue.use(VueCookies);

Vue.filter('numFormat', numFormat(numeral));
// load a locale
numeral.register('locale', 'de', {
  delimiters: {
    thousands: '.',
    decimal: ','
  },
  abbreviations: {
    thousand: 'k',
    million: 'm',
    billion: 'b',
    trillion: 't'
  },
  ordinal : function (number) {
    return number === 1 ? 'er' : 'ème';
  },
  currency: {
    symbol: '€'
  }
});
Vue.filter('str_limit', function (value, size) {
  if (!value) return '';
  value = value.toString();

  if (value.length <= size) {
    return value;
  }
  return value.substr(0, size) + '...';
});
Vue.filter('smallDate', function (value) {
  if(new Date(value) == "Invalid Date"){ return ""; }
  if(value == null){ return ""; }
  var _date = new Date(Date.parse(value));
  var day  = _date.getDate() < 10  ? "0"+_date.getDate() : _date.getDate();
  var month = toInteger(_date.getMonth())+1;
  month  = _date.getMonth() < 10  ? "0"+month : month;
  var date_str = day+"."+month+"."+_date.getFullYear();
  return date_str;
});
// switch between locales
numeral.locale('de');


Vue.config.productionTip = false;
router.beforeEach((to, from, next) => {
  window.scrollTo(0, 0);

  // More code ...
  next();
});
new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
