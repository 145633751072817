import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        name: "home",
        component: Home
    },
    {
        path: "/impressum",
        name: "impressum",
        component: () =>
            import("../views/static_pages/Impressum")
    },
    {
        path: "/agb",
        name: "agb",
        component: () =>
            import("../views/static_pages/Agb")
    },
    {
        path: "/datenschutz",
        name: "datenschutz",
        component: () =>
            import("../views/static_pages/Datenschutz")
    },
    {
        path: "/login",
        name: "login",
        component: () =>
            import("../views/Login")
    },
    {
        path: "/suche",
        name: "suche",
        component: () =>
            import("../views/Search")
    },
    {
        path: "/faecher",
        name: "faecher",
        component: () =>
            import("../views/ClassList")
    },
    {
        path: "/:cluster",
        name: "cluster",
        component: () =>
            import("../views/Cluster")
    },
    {
        path: "/download/:key",
        name: "download",
        component: () =>
            import("../views/Download")
    },
    {
        path: "/:cluster/:class",
        name: "class",
        component: () =>
            import("../views/Class")
    },
    {
        path: "/:cluster/:class/:letter",
        name: "letter",
        component: () =>
            import("../views/Letter")
    },
    {
        path: "/:cluster/:class/:letter/:item",
        name: "preview",
        component: () =>
            import("../views/Preview")
    },
    {
        path: "/about",
        name: "about",
        component: () =>
            import("../views/About.vue")
    }
];

const router = new VueRouter({
    mode: 'history',
    routes
});

export default router;
