<template>
    <div>
        <top-search></top-search>
        <div class="infoWraper">
            <div class="container">
                <div class="row text-center">
                    <div class="col-md-3">
                        <img src="@/assets/img/about_01.png" />
                        <h4>Kostenlose Downloads</h4>
                        <p>Alle Arbeiten kannst du kostenlos als PDF runterladen.</p>
                    </div>
                    <div class="col-md-3">
                        <img src="@/assets/img/about_02.png" />
                        <h4>Wissen teilen</h4>
                        <p>Teile dein Wissen mit anderen und lade deine Arbeit hoch</p>
                    </div>
                    <div class="col-md-3">
                        <img src="@/assets/img/about_03.png" />
                        <h4>Autorenhonorar</h4>
                        <p>Wir vergüten jede hochgeladenen Arbeit.</p>
                    </div>
                    <div class="col-md-3">
                        <img src="@/assets/img/about_04.png" />
                        <h4>Echte Arbeiten</h4>
                        <p>Alle Arbeiten werden geprüft</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="firstSlider">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <article-slider title="Beliebte Arbeiten" loader="hot"></article-slider>
                    </div>
                </div>
            </div>
        </div>
        <div class="adsense">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <Adsense v-if="adsense"
                                 data-ad-format="horizontal"
                                 data-ad-client="ca-pub-2696232756207990"
                                 data-ad-slot="8851516560">
                        </Adsense>
                    </div>
                </div>
            </div>
        </div>
        <div class="secondSlider">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <article-slider title="Neuste Arbeiten" loader="new"></article-slider>
                    </div>
                </div>
            </div>
        </div>
        <share-collect></share-collect>
        <div class="classList">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <class-list></class-list>
                     </div>
                </div>
            </div>
        </div>
        <div class="adsense">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <Adsense v-if="adsense"
                                 data-ad-format="horizontal"
                                 data-ad-client="ca-pub-2696232756207990"
                                 data-ad-slot="8851516560">
                        </Adsense>
                    </div>
                </div>
            </div>
        </div>
        <div class="thirdSlider">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <article-slider title="Zufällige Arbeiten" loader="random"></article-slider>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import ArticleSlider from "../components/ArticleSlider";
    import ClassList from "../components/ClassList";
    import ShareCollect from "../components/ShareCollect";
    import TopSearch from "../components/TopSearch";
    import Vue from "vue";
    import Ads from 'vue-google-adsense'
    Vue.use(require('vue-script2'))
    Vue.use(Ads.Adsense)
    Vue.use(Ads.InArticleAdsense)
    Vue.use(Ads.InFeedAdsense)
    export default {
        name: 'App',
        components: {
            TopSearch,
            ShareCollect,
            ArticleSlider,
            ClassList
        },
        data: function () {
            return {
                expandSearch : false,
                adsense : false
            }
        },
        created() {
            let cookieConsent=  Vue.$cookies.get("rephCookieCons");
            if(cookieConsent == '111') {
                this.adsense = true;
            } else if(cookieConsent == '101') {
                this.adsense = true;
            } else if(cookieConsent == '110') {
                this.adsense = false;
            }
        }
    }
</script>

<style scoped>
    .infoWraper img{
        margin-bottom:15px;
    }
    .infoWraper{
        padding: 35px 0 35px;
        color:white;
        background: #22316a;
    }
</style>

