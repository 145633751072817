<template>
   <div id="TopSearchWraper">
       <div class="container">
           <div class="row text-center">
               <div class="col-md-12">
                   <h1>Referate & Hausarbeiten finden</h1>
               </div>
           </div>
           <div class="row">
                <div class="col-sm-12 col-lg-6 offset-lg-3">
                    <div class="row">
                        <div class="col-sm-4 leftBox">
                            <div class="innerRightBox">
                                <div class="row">
                                    <div class="col-md-12">
                                        <select style="border:none" v-model="searchCluster" class="form-control">
                                            <option value="">Kategorie</option>
                                            <option value="referate">Referate</option>
                                            <option value="facharbeiten">Facharbeiten</option>
                                            <option value="seminararbeiten">Seminararbeiten</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-8 rightBox">
                            <div class="innerRightBox">
                                <div class="row">
                                    <div class="col-10">
                                        <input  style="border:none" placeholder="Wonach suchst du?" class="form-control" v-model="searchKey" v-on:keydown.enter="goSearch()">
                                    </div>
                                    <div class="col-2 text-right">
                                        <span v-on:click="goSearch()">
                                            <img style="height:34px" src="~@/assets/img/pausenhof_search-01.svg" />
                                        </span>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
           </div>
       </div>
   </div>
</template>

<script>
    export default {
        name: "TopSearch",
        data: function () {
            return{
                searchKey: "",
                searchCluster: "",
            }
        },
        methods: {
            goSearch : function () {
                let params = {
                    searchKey: this.searchKey,
                    searchCluster: this.searchCluster
                };
                localStorage.setItem('5234P34H_SKV', JSON.stringify(params));
                if(this.$route.name == "suche"){
                    this.$router.go(this.$router.currentRoute)
                }else{
                    this.$router.push("/suche");
                }
            }
        },created() {

        }
    };
</script>

<style scoped>
    @media (max-width: 576px){
        #TopSearchWraper .leftBox{
            margin: 0 15px 10px !important;
            border-radius: 20px !important;
            border-right: 0px !important;
        }
        #TopSearchWraper .rightBox img{
            margin-left:-10px;

        }
        #TopSearchWraper .rightBox{
            margin: 0 15px 10px !important;
            border-radius: 20px !important;
            border-left: 0px !important;
        }
    }
    #TopSearchWraper .form-control:focus{
        border:none;
        box-shadow: none;
    }
    #TopSearchWraper .leftBox{
        background:white;
        border-radius: 20px 0px 0px 20px;
        border-right: 5px solid #87cbf0;
        padding:10px;
    }
    #TopSearchWraper h1{
        font-family: 'Londrina Shadow';
        color:white;
        font-size: 3.5rem;
    }
    #TopSearchWraper .rightBox{
        background:white;
        padding:10px;
        border-left: 5px solid #87cbf0;
        border-radius: 0px 20px 20px 0px;
    }
    #TopSearchWraper{
        background-image: url('~@/assets/img/header_startseite-02.jpg');
        padding: 35px 0 35px;
        background-position: top center;
        background-size: cover;
    }
</style>