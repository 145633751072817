<template>
   <div id="TopBarWraper">
       <div class="container">
           <div class="row">
            <div class="col-sm">
                <router-link to="/">
                    <img src="@/assets/img/pausenhof_logo-01.png" />
                </router-link>
            </div>
            <div class="col-sm">
            </div>
           </div>
       </div>
   </div>
</template>

<script>
    export default {
        name: "TopBar",
    };
</script>

<style>
    #TopBarWraper{
        background: white;
        padding:25px 0 ;
    }
    #TopBarWraper ul{
        padding: 0;
        float:right;
    }
    #TopBarWraper ul li{
        cursor:pointer;
        list-style-type: none;
        float:left;
        margin-left:25px;
    }
</style>