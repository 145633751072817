<template>
   <div id="articleSliderWraper">
       <div class="row">
            <div class="col-sm">
                <h4>{{title}}</h4>
            </div>
       </div>
       <div class="row" v-show="loadComplete == true">
            <div class="col-sm-4" v-for="item in items" :key="item.id_item">
                <div class="card" >
                    <div class="card-body" >
                        <h5 class="card-title">{{item.item_title | str_limit(20)}}</h5>
                        <p class="card-text" style="min-height: 130px">{{item.item_plaintext | str_limit(150)}}</p>
                        <div class="text-center">
                            <span class="btn btn-primary ">
                                <router-link :to="{ name: 'preview', params: {cluster: item.cluster_formated,class: item.class_1_formated,letter: item.item_letter,item: item.url_id } }">
                                    Arbeit ansehen
                                </router-link>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
       </div>
       <div class="row" v-show="loadComplete == false">
           <div class="col-md-12">
               <div class="loadingBox">
                   <div class="row">
                       <div class="col-lg-12 text-center">
                           <br><br>
                           <b>Arbeiten werden geladen...</b>
                       </div>
                   </div>
                   <div class="row">
                       <div class="col-lg-12">
                           <div class="loadingBar" style="position: absolute;left:50%;top:15px;transform: translateX(-50%);">
                               <div class="lds-default"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
                           </div>
                       </div>
                   </div>
               </div>
           </div>
       </div>
   </div>
</template>

<script>
    import axios from "axios";

    export default {
        name: "ArticleSlider",
        props: {
            loader: String,
            title: String
        },
        data: function () {
            return {
                items: [],
                loadComplete : false
            }
        },
        methods:{
            getItems: function () {
                axios.get('https://api.pausenhof.de/item/list/'+this.loader,{params:{'limit': 1},headers:{'authorization': '3241451345'}}).then(response => {
                    this.items = response.data.content;
                    this.loadComplete = true;
                });
            }
        },
        created () {
            this.getItems();
        },
    };
</script>

<style>
    #articleSliderWraper .btn-primary{
        background:#f76d6d;
        border-color:#f76d6d;
        color:#fff !important;
    }
    #articleSliderWraper .btn-primary a{
        color:#fff !important;
    }
    #articleSliderWraper .btn-primary a:hover{
        text-decoration: none !important;
    }
    #articleSliderWraper{
        padding:25px 0;
    }
</style>