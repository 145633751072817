<template>
    <div id="FooterWraper">
        <div class="container">
            <div class="row">
                <div class="col-sm footerLogo">
                    <router-link to="/">
                        <img src="@/assets/img/pausenhof_logo-01.png" />
                    </router-link>
                </div>
            </div>
            <div class="row">
                <div class="col-sm">
                    <ul>
                        <li>
                            <router-link to="/referate">
                                Referate
                            </router-link>
                        </li>
                        <li>
                            <router-link to="/facharbeiten">
                                Facharbeiten
                            </router-link>
                        </li>
                        <li>
                            <router-link to="/seminararbeiten">
                                Seminararbeiten
                            </router-link>
                        </li>
                    </ul>
                </div>
                <div class="col-sm">
                    <ul>
                        <li>
                            <router-link to="/datenschutz" class="mega-menu">
                                Datenschutz
                            </router-link>
                        </li>
                        <li>
                            <router-link to="/agb" class="mega-menu">
                                AGB
                            </router-link>
                        </li>
                        <li>
                            <router-link to="/impressum" class="mega-menu">
                                Impressum
                            </router-link>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "Footer",
        data: function () {
            return {
                backgroundUrl: 'Hello'
            }
        },
    };
</script>

<style>
    #FooterWraper a{
        color:white;
    }
    #FooterWraper{
        background-image: url('~@/assets/img/footer_doodle-02.png');
        background-color: #373737;
        color: white;
        padding: 35px 0;
        background-position: bottom center;
        background-size: cover;
    }
    .footerLogo{
        margin-bottom:15px;
    }
    #FooterWraper ul{
        padding: 0;
    }
    #FooterWraper ul li{
        cursor:pointer;
        list-style-type: none;
    }
</style>