<template>
  <div id="app">
    <!-- Header -->
    <TopBar></TopBar>
    <!-- Main Content -->
    <section class="content">
      <router-view></router-view>
    </section>
    <!-- Footer -->
    <Footer></Footer>
    <div v-if="!cookieSet" id="overlayShadow"></div>
    <div v-if="!cookieSet" id="cookieBox">
      <div class="row">
        <div class="col-md-12">
          <div class="card" >
            <div class="card-body">
              <h5 class="card-title">
                Wir verwenden Cookies
              </h5>
              <p class="card-text">
                Für Dein optimales Seitenerlebnis erheben wir mit Hilfe von Cookies Nutzungsinformationen, die wir auch mit unseren Partnern teilen.
                Die Cookies sind entweder für die Seitenfunktion notwendig, dienen Analyse- und Marketingzwecken oder personalisieren Dein Benutzererlebnis.
                <br><br>
                Links:
                Impressum und Datenschutz
              </p>
              <div class="row">
                <div class="col-md-4">
                  <div class="custom-control custom-switch">
                    <input type="checkbox" class="custom-control-input" disabled id="cookieOption1" v-model="cookieLevel.level1">
                    <label class="custom-control-label" for="cookieOption1">Notwendig</label>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="custom-control custom-switch">
                    <input type="checkbox" class="custom-control-input" id="cookieOption2" v-model="cookieLevel.level2">
                    <label class="custom-control-label" for="cookieOption2">Persönlich</label>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="custom-control custom-switch">
                    <input type="checkbox" class="custom-control-input" id="cookieOption3" v-model="cookieLevel.level3">
                    <label class="custom-control-label" for="cookieOption3">Personalisierung</label>
                  </div>
                </div>
              </div>
              <br>
              <div class="row">
                <div class="col-sm-8">
                  <a href="#" v-on:click="cookieSave(false)"  class="btn btn-outline-secondary">Auswahl bestätigen</a>
                  <a href="#" style="margin-left:5px" v-on:click="declineCookieSave()"  class="btn btn-outline-secondary">Alle ablehnen</a>
                </div>
                <div class="col-sm-4 text-right" >
                  <a href="#" v-on:click="cookieSave(true)" class="btn btn-primary">Alle Auswählen</a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TopBar from "./components/TopBar";
import Footer from "./components/Footer";
import Vue from 'vue';
import VueAnalytics from "vue-analytics";
import router from "@/router";
//import Ads from 'vue-google-adsense';

export default {
  name: 'App',
  components: {
    TopBar,
    Footer
  },
  data() {
    return {
      cookieSet : false,
      cookieLevel: {
        "level1": true,
        "level2": false,
        "level3": false
      },
    }
  },
  created: function () {
    let cookie=  Vue.$cookies.get("rephCookieCons");
    if(cookie == null){
      //need Cookie
    }else{
      this.cookieActivation(cookie);
      this.cookieSet = true;
    }
  },
  methods: {
      jsLoad(url){
        var script = document.createElement("script")
        script.type = "text/javascript";
        script.async = true;
        script.setAttribute("data-ad-client","ca-pub-2696232756207990")
        if(script.readyState) {
          script.onreadystatechange = function() {
            if (script.readyState == "loaded" || script.readyState == "complete") {
              script.onreadystatechange = null;
            }
          };
        }
        script.src = url;
        document.getElementsByTagName("head")[0].appendChild(script);
      },
      cookieSave(all){
        if(all == true){
          Vue.$cookies.set("rephCookieCons","111");
          this.cookieActivation(111);
        }else{
          let cookie = "";
          cookie += this.cookieLevel.level1 == true ? "1" : "0";
          cookie += this.cookieLevel.level2 == true ? "1" : "0";
          cookie += this.cookieLevel.level3 == true ? "1" : "0";
          Vue.$cookies.set("rephCookieCons",cookie);
          this.cookieActivation(cookie);
        }
        this.cookieSet = true;
      },
      declineCookieSave(){
        Vue.$cookies.set("rephCookieCons","000");
        this.cookieActivation("000");
        this.cookieSet = true;
      },
      cookieActivation(cookieConsent){
        if(cookieConsent == '111') {
          //lade Alles
          //this.jsLoad("https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js");
          //Vue.use(require('vue-script2'));

          Vue.use(VueAnalytics,{
            id: 'UA-117271430-4',
            router
          });

          //Vue.use(Ads.AutoAdsense, { adClient: 'ca-pub-2696232756207990' });
        } else if(cookieConsent == '101') {
          //Notwendig und Personalisiert
         // this.jsLoad("https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js");
          //Vue.use(require('vue-script2'));

          Vue.use(VueAnalytics,{
            id: 'UA-117271430-4',
            router
          });

          //Vue.use(Ads.AutoAdsense, { adClient: 'ca-pub-2696232756207990' });
        } else if(cookieConsent == '110') {

          //Notwendig und Persönlich
        }
      }
  }
}
</script>

<style>
  .custom-switch label{cursor: pointer}
body{background-color:#f6fafd !important;}
.breadCrump{text-transform: capitalize;margin-top:5px;}
  @media (max-width: 576px){
    #cookieBox{
      top:20px !important;
      max-height:90% !important;
      overflow-y: auto;
      overflow-x: hidden;
    }
    #cookieBox .btn{
      width: 100%;
      margin-bottom: 15px;
    }
  }
#overlayShadow{
  position: fixed;
  top:0;
  left:0;
  width: 100%;
  height: 100%;
  background: #000000af;
  z-index:999999;
}
#cookieBox{
  position: fixed;
  box-sizing: border-box;
  margin: 0 auto;
  top: 50px;
  width: 600px;
  z-index: 999999999;
  max-width: 90%;
  background: #fff;
  min-height: 250px;
  transform: translateX(-50%);
  left: 50%;
}
#app {
  font-family: 'Quicksand', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

#FooterWraper a:hover{
  text-decoration: none;
}
/*LOADINGBAR*/
.lds-default {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-default div {
  position: absolute;
  width: 6px;
  height: 6px;
  background: #87cbf0;
  border-radius: 50%;
  animation: lds-default 1.2s linear infinite;
}
.lds-default div:nth-child(1) {
  animation-delay: 0s;
  top: 37px;
  left: 66px;
}
.lds-default div:nth-child(2) {
  animation-delay: -0.1s;
  top: 22px;
  left: 62px;
}
.lds-default div:nth-child(3) {
  animation-delay: -0.2s;
  top: 11px;
  left: 52px;
}
.lds-default div:nth-child(4) {
  animation-delay: -0.3s;
  top: 7px;
  left: 37px;
}
.lds-default div:nth-child(5) {
  animation-delay: -0.4s;
  top: 11px;
  left: 22px;
}
.lds-default div:nth-child(6) {
  animation-delay: -0.5s;
  top: 22px;
  left: 11px;
}
.lds-default div:nth-child(7) {
  animation-delay: -0.6s;
  top: 37px;
  left: 7px;
}
.lds-default div:nth-child(8) {
  animation-delay: -0.7s;
  top: 52px;
  left: 11px;
}
.lds-default div:nth-child(9) {
  animation-delay: -0.8s;
  top: 62px;
  left: 22px;
}
.lds-default div:nth-child(10) {
  animation-delay: -0.9s;
  top: 66px;
  left: 37px;
}
.lds-default div:nth-child(11) {
  animation-delay: -1s;
  top: 62px;
  left: 52px;
}
.lds-default div:nth-child(12) {
  animation-delay: -1.1s;
  top: 52px;
  left: 62px;
}
@keyframes lds-default {
  0%, 20%, 80%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.5);
  }
}
  .loadingBox{
    text-transform: uppercase;
    background: rgb(255 255 255);
    min-height: 204px;
    position: relative;
    border: 3px solid #87cbf0;
    border-radius: 20px;
    margin: 10px 0 25px;
  }


  @font-face {
    font-family: "Londrina Shadow";
    src: local("Londrina"),
    url(./assets/fonts/Londrina.ttf) format("truetype");
  }
  @font-face {
    font-family: 'Quicksand';
    src: local("Quicksand"),
    url(./assets/fonts/Quicksand-Regular.ttf) format("truetype");
  }
</style>
